<template>
  <!-- <div>
    <input type="hidden" role="uploadcare-uploader" name="my_file" 
    data-tabs="file"
    data-effects="crop"
    data-crop="2:1"/>
  </div> -->

  <!-- LINK TO CODE:
  https://codepen.io/stenvdb/pen/wvBoYQO
  -->
  <div id="app">
    <div
      class="
        mt-4
        relative
        block
        w-full
        border-2 border-gray-300 border-dashed
        rounded-lg
        text-center
        bg-center bg-cover
        hover:border-gray-400
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-indigo-500
        aspect-w-9 aspect-h-4
      "
      v-bind:style="{ 'background-image': 'url(' + currentHero + ')' }"
    >
    <!-- <thiswasintheaboveclass @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"/> -->
      
      <!-- <input
        type="file"
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="file"
        accept=".pdf,.jpg,.jpeg,.png"
      /> -->

      <!-- <label for="assetsFieldHandle" class="block cursor-pointer">
        <div>
          <svg
            class="
              pointer-events-none
              mx-auto
              h-12
              w-12
              text-white
              relative
              z-20
            "
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
            />
          </svg>
          <span
            class="
              pointer-events-none
              mt-2
              block
              text-sm
              font-medium
              text-white
              relative
              z-20
            "
          >
            Upload new hero image
          </span>
        </div>
      </label> -->
      <!-- <ul class="mt-4" v-if="this.filelist.length" v-cloak style="list-style-type: none;">
        <li class="text-sm p-1" v-for="file in filelist" :key="file">
          {{ file.name
          }}<button
            class="ml-2"
            type="button"
            @click="remove(filelist.indexOf(file))"
            title="Remove file"
          >
            X
          </button>
        </li>
      </ul> -->
    </div>
  </div>

  <div class="mt-2">
    <input
      type="hidden"
      role="uploadcare-uploader"
      name="my_file"
      id="uploader"
      data-tabs="file"
      data-effects="crop"
      data-crop="2:1"
    />
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { UploadcareService } from "../services/uploadcareService";
import uploadcare from "uploadcare-widget";

const currentHero =
  "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80";

export default {
  el: "#app",
  delimiters: ["${", "}"], // Avoid Twig conflicts
  props: {
    heroUuid: String,
  },
  async created() {
    if (this.heroUuid) {
      this.currentHero = UploadcareService.buildImageUrl(
        this.heroUuid,
        "-/resize/x900/"
      );
    }
  },
  mounted() {
    // get a widget reference
    //const widget = uploadcare.Widget("#uploader", { publicKey: '984f2defc29b6383bfc8' });
    const widget = uploadcare.SingleWidget("[id=uploader]", { publicKey: '984f2defc29b6383bfc8' });

    // listen to the "upload completed" event
    widget.onUploadComplete(async (fileInfo) => {
      if (fileInfo) {
        let oldImage = this.heroUuid;
        // get a CDN URL from the file info
        this.currentHero = fileInfo.cdnUrl;
        let resp = await UploadcareService.saveCroppedImage(fileInfo.cdnUrl);
        if (resp.status == 201 || resp.status == 200) {
          this.$emit("heroUpload", resp.data.result);
          this.$emit("oldImageToRemove", oldImage);
        }
        widget.value(null);
      }

    });
  },
  data() {
    return {
      filelist: [], // Store our uploaded files
      fileUUIDs: [],
      currentHero,
    };
  },
  methods: {
    onChange() {
      // this.filelist = [...this.$refs.file.files];
      // this.currentHero = URL.createObjectURL(this.filelist[0]);
      // this.$emit("heroUpload", this.filelist[0]);
      // this.testUploadcareServiceUPLOAD(this.filelist[0]);
    },
    // remove(i) {
    //   this.filelist.splice(i, 1);
    //   this.testUploadcareServiceDELETE(i);
    // },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>
<style>
[v-cloak] {
  display: none;
}

 .uploadcare--widget__button.uploadcare--widget__button_type_open {
      background-color: #5b49f0;
  }
</style>
