<template>
  <div id="app">
    <div class="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0"
      >
      <!-- <forabovediv @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"> -->
      <p class="text-sm font-medium text-gray-700" aria-hidden="true">Photo</p>
      <div class="mt-1 lg:hidden">
        <div class="flex items-center">
          <div
            class="
              flex-shrink-0
              inline-block
              rounded-full
              overflow-hidden
              h-12
              w-12
              hidden
            "
            aria-hidden="true"
          >
            <img
              class="rounded-full h-full w-full"
              :src="currentProfileUrl"
              alt=""
            />
          </div>
          <div class="hidden ml-5 rounded-md shadow-sm">
            <div
              class="
                group
                relative
                border border-gray-300
                rounded-md
                py-2
                px-3
                flex
                items-center
                justify-center
                hover:bg-gray-50
                focus-within:ring-2
                focus-within:ring-offset-2
                focus-within:ring-sky-500
              "
            >
              <!-- <label
                for="mobile-user-photo"
                class="
                  relative
                  text-sm
                  leading-4
                  font-medium
                  text-gray-700
                  pointer-events-none
                "
              >
                <span>Change</span>
                <span class="sr-only"> user photo</span>
              </label>
              <input
                id="mobile-user-photo"
                name="user-photo"
                type="file"
                class="
                  absolute
                  w-full
                  h-full
                  opacity-0
                  cursor-pointer
                  border-gray-300
                  rounded-md
                "
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png"
              />
              <input
                type="file"
                name="fields[mobile-user-photo][]"
                id="mobile-user-photo"
                class="absolute
                  w-full
                  h-full
                  opacity-0
                  cursor-pointer
                  border-gray-300
                  rounded-md"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png"
              /> -->
            </div>
          </div>
        </div>
      </div>

      <div class="relative rounded-full overflow-hidden lg:block">
        <img
          class="relative rounded-full w-40 h-40"
          :src="currentProfileUrl"
          alt=""
        />
        <!-- <label
          for="desktop-user-photo"
          class="
            absolute
            inset-0
            w-full
            h-full
            bg-black bg-opacity-75
            flex
            items-center
            justify-center
            text-sm
            font-medium
            text-white
            opacity-0
            hover:opacity-100
            focus-within:opacity-100
          "
        >
          <span>Change</span>
          <span class="sr-only"> user photo</span>
          <input
            type="file"
            name="fields[desktop-user-photo][]"
            id="desktop-user-photo"
            class="absolute
              inset-0
              w-full
              h-full
              opacity-0
              cursor-pointer
              border-gray-300
              rounded-md"
            @change="onChange"
            ref="file"
            accept=".pdf,.jpg,.jpeg,.png"
            />
        </label> -->
      </div>
  <div class="text-center mt-2" >
    <input
      type="hidden"
      role="uploadcare-uploader2"
      name="my_file"
      id="uploader2"
      data-tabs="file"
      data-effects="crop"
      data-crop="1:1"
    />
  </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { UploadcareService } from "../services/uploadcareService";
import uploadcare from "uploadcare-widget";

// const user = {
//   imageUrl:
//     "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
// };

const currentProfileUrl = "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"

export default {
  el: "#app",
  delimiters: ["${", "}"], // Avoid Twig conflicts
//   setup() {
//       return {
//           user,
//       }
//   },
  props:{
    profileUuid: String
  },
  async created() {
    if(this.profileUuid) {
      this.currentProfileUrl = UploadcareService.buildImageUrl(this.profileUuid, "-/resize/200/");
    }
  },
  mounted() {
    // get a widget reference
    //const widget = uploadcare.Widget("#uploader", { publicKey: '984f2defc29b6383bfc8' });
    const widget = uploadcare.SingleWidget("[id=uploader2]", { publicKey: '984f2defc29b6383bfc8' });

    // listen to the "upload completed" event
    widget.onUploadComplete(async (fileInfo) => {
      if (fileInfo) {
        let oldImage = this.profileUuid;
        // get a CDN URL from the file info
        this.currentProfileUrl = fileInfo.cdnUrl;
        let resp = await UploadcareService.saveCroppedImage(fileInfo.cdnUrl);
        if (resp.status == 201 || resp.status == 200) {
          this.$emit("profileUpload", resp.data.result);
          this.$emit("oldProfileToRemove", oldImage);
        }
        widget.value(null);
      }

    });
  },
  data() {
    return {
      //user,
      filelist: [], // Store our uploaded files
      fileUUIDs: [],
      currentProfileUrl,
    };
  },
  methods: {
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.currentProfileUrl = URL.createObjectURL(this.filelist[0]);
      this.$emit("profileUpload", this.filelist[0]);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        // event.currentTarget.classList.remove("bg-gray-100");
        // event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },
    async testUploadcareServiceUPLOAD(file) {
      let respUpload = await UploadcareService.uploadProfileImage(
        "username",
        file.name,
        file
      );
      this.fileUUIDs.push(respUpload.data[file.name]);
      let respGet = await UploadcareService.getImageData(
        respUpload.data[file.name]
      );
      this.currentProfileUrl = respGet.data.original_file_url;
    },
  },
};
</script>
<style>
[v-cloak] {
  display: none;
}
</style>
