<template>
  <div v-if="sectionData">
    <Disclosure
      as="div"
      class="relative bg-indigo-600 pb-32 overflow-hidden"
      v-slot="{ open }"
      ref="topofpage"
    >
      <div
        :class="[
          open ? 'bottom-0' : 'inset-y-0',
          'absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0',
        ]"
        aria-hidden="true"
      >
        <div class="flex-grow bg-indigo-600 bg-opacity-75" />
        <svg
          class="flex-shrink-0"
          width="1750"
          height="308"
          viewBox="0 0 1750 308"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity=".1"
            d="M1465.84 308L16.816 0H1750v308h-284.16z"
            fill="#fff"
          />
          <path
            opacity=".05"
            d="M1733.19 0L284.161 308H0V0h1733.19z"
            fill="#fff"
          />
        </svg>
        <div class="flex-grow bg-indigo-600 bg-opacity-75" />
      </div>
      <header class="relative py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Settings</h1>
        </div>
      </header>
    </Disclosure>

    <main class="relative -mt-32">
      <div class="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
        <div class="bg-white rounded-lg shadow overflow-hidden">
          <div
            class="
              divide-y divide-gray-200
              lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x
            "
          >
            <aside class="py-6 lg:col-span-3">
              <nav class="space-y-1">
                <a
                  v-for="item in subNavigation"
                  :key="item.name"
                  :href="item.href"
                  :class="[
                    item.current
                      ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                      : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-teal-500 group-hover:text-teal-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  <span class="truncate">
                    {{ item.name }}
                    <div
                      class="inline-block ml-1 text-blue-400 font-medium"
                      v-if="item.privacySetting && privateAccount"
                    >
                      (Private)
                    </div>
                  </span>
                </a>
              </nav>
            </aside>

            <LoadingSpinner
              v-if="isLoading"
              class="divide-y divide-gray-200 lg:col-span-9"
            />
            <form
              v-if="!isLoading && userData"
              class="divide-y divide-gray-200 lg:col-span-9"
            >
              <!-- Profile section -->
              <div class="py-6 px-4 sm:p-6 lg:pb-8">
                <div>
                  <h2 class="text-lg leading-6 font-medium text-gray-900">
                    {{ sectionData[0].topText }}
                  </h2>
                  <p class="mt-1 text-sm text-gray-500">
                    {{ sectionData[0].middleText }}
                  </p>
                </div>

                <div class="mt-6 flex flex-col lg:flex-row">
                  <div class="flex-grow space-y-6">
                    <!-- Success message -->
                    <div
                      v-if="showSuccess"
                      class="rounded-md bg-green-50 p-4 mb-8"
                    >
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <!-- Heroicon name: solid/check-circle -->
                          <svg
                            class="h-5 w-5 text-green-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-green-800">
                            Successfully saved changes
                          </h3>
                          <!--<div class="mt-2 text-sm text-green-700">
                            <p class="text-green-700  text-sm">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.
                            </p>
                          </div>-->
                        </div>
                      </div>
                    </div>
                    <!-- Failure message -->
                    <div
                      v-if="showFailure"
                      class="rounded-md bg-red-50 p-4 mb-8"
                    >
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <!-- Heroicon name: solid/x-circle -->
                          <svg
                            class="h-5 w-5 text-red-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">
                            Failed to save changes
                          </h3>
                          <div class="mt-2 text-red-700">
                            <p class="text-red-700 text-sm">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Aliquid pariatur, ipsum similique veniam.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        for="username"
                        class="block text-sm mb-3 font-medium text-gray-700"
                      >
                        Hero image
                      </label>
                      <HeroUploader
                        v-bind:heroUuid="userData.HeroImageUUID"
                        v-on:heroUpload="onHeroUpload"
                        v-on:oldImageToRemove="onOldImageToRemove"
                      />
                      <!-- <input
                          type="hidden"
                          role="uploadcare-uploader"
                          data-public-key="984f2defc29b6383bfc8"
                          data-tabs="file"
                          data-effects="crop"
                          data-crop="2:1"
                      /> -->
                    </div>
                  </div>

                  <ProfileUploader
                    v-bind:profileUuid="userData.ProfileImageUUID"
                    v-on:profileUpload="onProfileUpload"
                    v-on:oldProfileToRemove="onOldProfileToRemove"
                  />
                </div>
              </div>
              <div class="py-6 px-4">
                <label
                  for="username"
                  class="block text-sm font-medium text-gray-700"
                >
                  Profile URL
                </label>
                <div class="mt-1 rounded-md shadow-sm flex">
                  <span
                    class="
                      bg-gray-50
                      border border-r-0 border-gray-300
                      rounded-l-md
                      px-3
                      inline-flex
                      items-center
                      text-gray-500
                      sm:text-sm
                    "
                  >
                    krickers.com/
                  </span>
                  <input
                    @input="updateField('Username', $event.target.value)"
                    type="text"
                    name="username"
                    id="username"
                    autocomplete="username"
                    class="
                      focus:ring-sky-500 focus:border-sky-500
                      flex-grow
                      block
                      w-full
                      min-w-0
                      rounded-none rounded-r-md
                      sm:text-sm
                      border-gray-300
                    "
                    :value="userData.Username"
                    @blur="generateProfileUrl()"
                  />
                </div>
              </div>
              <div class="py-6 px-4">
                <label
                  for="about"
                  class="block text-sm font-medium text-gray-700"
                >
                  About
                </label>
                <div class="mt-1">
                  <textarea
                    @input="updateField('About', $event.target.value)"
                    id="about"
                    name="about"
                    rows="3"
                    class="
                      shadow-sm
                      focus:ring-sky-500 focus:border-sky-500
                      mt-1
                      block
                      w-full
                      sm:text-sm
                      border border-gray-300
                      rounded-md
                    "
                    :value="userData.About"
                  />
                </div>
                <p class="mt-2 text-sm text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3 inline-block opacity-60"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Brief description for your profile.
                </p>
              </div>
              <div class="divide-y divide-gray-200 py-6 px-4">
                <div class="grid grid-cols-12 gap-6">
                  <div class="col-span-12 sm:col-span-6">
                    <label
                      for="first-name"
                      class="block text-sm font-medium text-gray-700"
                      >First name <span class="text-red-500">*</span></label
                    >
                    <input
                      @input="updateField('FirstName', $event.target.value)"
                      type="text"
                      name="first-name"
                      :value="userData.FirstName"
                      id="first-name"
                      autocomplete="given-name"
                      class="
                        mt-1
                        block
                        w-full
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        py-2
                        px-3
                        focus:outline-none
                        focus:ring-sky-500
                        focus:border-sky-500
                        sm:text-sm
                      "
                      v-bind:class="[
                        !userData.FirstName
                          ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                          : '',
                      ]"
                    />
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label
                      for="last-name"
                      class="block text-sm font-medium text-gray-700"
                      >Last name <span class="text-red-500">*</span></label
                    >
                    <input
                      @input="updateField('LastName', $event.target.value)"
                      type="text"
                      name="last-name"
                      id="last-name"
                      :value="userData.LastName"
                      autocomplete="family-name"
                      class="
                        mt-1
                        block
                        w-full
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        py-2
                        px-3
                        focus:outline-none
                        focus:ring-sky-500
                        focus:border-sky-500
                        sm:text-sm
                      "
                      v-bind:class="[
                        !userData.LastName
                          ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                          : '',
                      ]"
                    />
                  </div>
                  <div class="col-span-12 sm:col-span-12 -mt-6">
                    <div>
                      <SwitchGroup
                        as="li"
                        class="py-4 flex items-center justify-between"
                      >
                        <div class="flex flex-col">
                          <SwitchLabel
                            as="p"
                            class="text-sm font-medium text-gray-900"
                            passive
                          >
                            Use Display Name
                          </SwitchLabel>
                          <SwitchDescription class="text-sm text-gray-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-3 w-3 inline-block opacity-60"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            This is the name that will be displayed on your
                            profile instead of First and Last name.
                          </SwitchDescription>
                        </div>
                        <Switch
                          v-model="displayNameToggle"
                          :class="[
                            displayNameToggle ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500',
                          ]"
                          v-on:click="
                            updateField('ShowDisplayName', displayNameToggle)
                          "
                        >
                          <span
                            aria-hidden="true"
                            :class="[
                              displayNameToggle
                                ? 'translate-x-4'
                                : 'translate-x-0',
                              'inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                            ]"
                          />
                        </Switch>
                      </SwitchGroup>
                    </div>
                    <div v-if="displayNameToggle">
                      <label
                        for="display-name"
                        class="block text-sm font-medium text-gray-700"
                        >Display name</label
                      >
                      <input
                        @input="updateField('DisplayName', $event.target.value)"
                        type="text"
                        name="display-name"
                        id="display-name"
                        :value="userData.DisplayName"
                        autocomplete="display-name"
                        class="
                          mt-1
                          block
                          w-full
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          py-2
                          px-3
                          focus:outline-none
                          focus:ring-sky-500
                          focus:border-sky-500
                          sm:text-sm
                        "
                        v-bind:class="[
                          displayNameToggle && !userData.DisplayName
                            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                            : '',
                        ]"
                      />
                    </div>
                  </div>

                  <div class="col-span-12">
                    <label
                      for="url"
                      class="block text-sm font-medium text-gray-700"
                      >Website</label
                    >
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <span
                        class="
                          inline-flex
                          items-center
                          px-3
                          rounded-l-md
                          border border-r-0 border-gray-300
                          bg-gray-50
                          text-gray-500
                          sm:text-sm
                        "
                      >
                        http://
                      </span>
                      <input
                        @input="updateField('WebsiteUrl', $event.target.value)"
                        type="text"
                        name="company-website"
                        id="company-website"
                        class="
                          flex-1
                          py-2
                          px-3
                          min-w-0
                          block
                          w-full
                          px-3
                          py-2
                          rounded-none rounded-r-md
                          focus:ring-indigo-500 focus:border-indigo-500
                          sm:text-sm
                          border-gray-300
                        "
                        placeholder="www.example.com"
                        :value="userData.WebsiteUrl"
                      />
                    </div>
                  </div>

                  <div class="col-span-12 sm:col-span-6">
                    <label
                      for="company"
                      class="block text-sm font-medium text-gray-700"
                      >Email Address</label
                    >
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <div
                        class="
                          absolute
                          inset-y-0
                          left-0
                          pl-3
                          flex
                          items-center
                          pointer-events-none
                        "
                      >
                        <MailIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        @input="updateField('Email', $event.target.value)"
                        type="text"
                        name="email"
                        id="email"
                        class="
                          focus:ring-indigo-500
                          py-2
                          px-3
                          focus:border-indigo-500
                          block
                          w-full
                          pl-10
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="you@example.com"
                        :value="userData.Email"
                        v-bind:class="[
                          !userData.Email
                            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                            : '',
                        ]"
                      />
                    </div>
                    <div>
                      <SwitchGroup
                        as="li"
                        class="py-4 flex items-center justify-between"
                      >
                        <div class="flex flex-col">
                          <SwitchLabel
                            as="p"
                            class="text-sm font-medium text-gray-900"
                            passive
                          >
                            Display Email Address
                          </SwitchLabel>
                          <SwitchDescription class="text-sm text-gray-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-3 w-3 inline-block opacity-60"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Show email address on public profile.
                          </SwitchDescription>
                        </div>
                        <Switch
                          v-model="displayEmail"
                          :class="[
                            displayEmail ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500',
                          ]"
                          v-on:click="updateField('DisplayEmail', displayEmail)"
                        >
                          <span
                            aria-hidden="true"
                            :class="[
                              displayEmail ? 'translate-x-4' : 'translate-x-0',
                              'inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                            ]"
                          />
                        </Switch>
                      </SwitchGroup>
                    </div>
                  </div>
                  <div class="col-span-12 sm:col-span-6">
                    <div>
                      <label
                        for="phone-number"
                        class="block text-sm font-medium text-gray-700"
                        >Phone Number</label
                      >
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <div
                          class="absolute inset-y-0 left-0 flex items-center"
                        >
                          <label for="country" class="sr-only">Country</label>
                          <select
                            id="country"
                            name="country"
                            class="
                              focus:ring-indigo-500 focus:border-indigo-500
                              h-full
                              py-0
                              pl-3
                              pr-7
                              border-transparent
                              bg-transparent
                              text-gray-500
                              sm:text-sm
                              rounded-md
                            "
                          >
                            <option>CA</option>
                          </select>
                        </div>
                        <input
                          @input="updateField('Phone', $event.target.value)"
                          type="text"
                          name="phone-number"
                          id="phone-number"
                          class="
                            focus:ring-indigo-500 focus:border-indigo-500
                            block
                            w-full
                            pl-16
                            py-2
                            sm:text-sm
                            border-gray-300
                            rounded-md
                          "
                          placeholder="(555) 987-6543"
                          :value="userData.Phone"
                          v-mask="['+1 (###) ###-####']"
                        />
                      </div>

                      <div>
                        <SwitchGroup
                          as="li"
                          class="py-4 flex items-center justify-between"
                        >
                          <div class="flex flex-col">
                            <SwitchLabel
                              as="p"
                              class="text-sm font-medium text-gray-900"
                              passive
                            >
                              Display Phone Number
                            </SwitchLabel>
                            <SwitchDescription class="text-sm text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-3 w-3 inline-block opacity-60"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              Show phone number on public profile.
                            </SwitchDescription>
                          </div>
                          <Switch
                            v-model="displayPhone"
                            :class="[
                              displayPhone ? 'bg-indigo-600' : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500',
                            ]"
                            v-on:click="
                              updateField('DisplayPhone', displayPhone)
                            "
                          >
                            <span
                              aria-hidden="true"
                              :class="[
                                displayPhone
                                  ? 'translate-x-4'
                                  : 'translate-x-0',
                                'inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                              ]"
                            />
                          </Switch>
                        </SwitchGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-6 divide-y divide-gray-200 pb-10">
                <div class="px-4 sm:px-6">
                  <div>
                    <h2 class="text-lg leading-6 font-medium text-gray-900">
                      Address
                    </h2>
                  </div>
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-12 sm:col-span-3">
                      <label
                        for="country"
                        class="block text-sm font-medium text-gray-700"
                        >Country / Region</label
                      >
                      <select
                        @change="updateField('Country', $event.target.value)"
                        id="country"
                        name="country"
                        autocomplete="country"
                        class="
                          mt-1
                          block
                          w-full
                          bg-white
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          py-2
                          px-3
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                      >
                        <option selected>Canada</option>
                      </select>
                    </div>

                    <div class="col-span-12 sm:col-span-3">
                      <label
                        for="street-address"
                        class="block text-sm font-medium text-gray-700"
                        >Street address</label
                      >
                      <input
                        @input="updateField('Address', $event.target.value)"
                        placeholder="123 Street Name Road"
                        type="text"
                        name="street-address"
                        id="street-address"
                        :value="userData.Address"
                        autocomplete="street-address"
                        class="
                          mt-1
                          block
                          w-full
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          py-2
                          px-3
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                      />
                    </div>

                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        for="city"
                        class="block text-sm font-medium text-gray-700"
                        >City <span class="text-red-500">*</span></label
                      >
                      <input
                        @input="updateField('City', $event.target.value)"
                        type="text"
                        name="city"
                        id="city"
                        :value="userData.City"
                        class="
                          mt-1
                          block
                          w-full
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          py-2
                          px-3
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                        v-bind:class="[
                          !userData.City
                            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                            : '',
                        ]"
                      />
                    </div>

                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label for="state" class="block text-sm font-medium text-gray-700">State / Province</label>
                      <select @change="updateField('Province', $event.target.value)"  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      v-bind:class="[
                        !userData.Province
                          ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                          : '',
                        ]">
                        <option :selected="!userData.Province" value="" disabled>-- Please Select --</option>
                        <option :selected="userData.Province == 'Alberta'" value="Alberta">Alberta</option>
                        <option :selected="userData.Province == 'British Columbia'">British Columbia</option>
                        <option :selected="userData.Province == 'Manitoba'">Manitoba</option>
                        <option :selected="userData.Province == 'New Brunswick'">New Brunswick</option>
                        <option :selected="userData.Province == 'Newfoundland and Labrador'">Newfoundland and Labrador</option>
                        <option :selected="userData.Province == 'Northwest Territories'">Northwest Territories</option>
                        <option :selected="userData.Province == 'Nova Scotia'">Nova Scotia</option>
                        <option :selected="userData.Province == 'Nunavut'">Nunavut</option>
                        <option :selected="userData.Province == 'Ontario'">Ontario</option>
                        <option :selected="userData.Province == 'Prince Edward Island'">Prince Edward Island</option>
                        <option :selected="userData.Province == 'Quebec'">Quebec</option>
                        <option :selected="userData.Province == 'Saskatchewan'">Saskatchewan</option>
                        <option :selected="userData.Province == 'Yukon'">Yukon</option>
                      </select>
                    </div>

                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        for="postal-code"
                        class="block text-sm font-medium text-gray-700"
                        >ZIP / Postal <span class="text-red-500">*</span></label
                      >
                      <input
                        @input="
                          updateField('ZipPostalCode', $event.target.value)
                        "
                        type="text"
                        :value="userData.ZipPostalCode"
                        placeholder="A1A 2B2"
                        name="postal-code"
                        id="postal-code"
                        autocomplete="postal-code"
                        v-mask="['A#A #A#']"
                        class="
                          mt-1
                          block
                          w-full
                          border border-gray-300
                          rounded-md
                          shadow-sm
                          py-2
                          px-3
                          focus:outline-none
                          focus:ring-indigo-500
                          focus:border-indigo-500
                          sm:text-sm
                        "
                        v-bind:class="[
                          !userData.ZipPostalCode
                            ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                            : '',
                        ]"
                      />
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label
                        for="travel-radius"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Travel Radius
                      </label>
                      <p id="" class="text-sm text-gray-500 mb-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3 w-3 inline-block opacity-60"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        How far are you willing to travel?
                      </p>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          @input="updateField('Radius', $event.target.value)"
                          :value="userData.Radius"
                          type="number"
                          name="travel-radius"
                          id="travel-radius"
                          class="
                            flex-1
                            min-w-0
                            block
                            w-full
                            px-3
                            py-2
                            rounded-none rounded-l-md
                            focus:ring-indigo-500 focus:border-indigo-500
                            sm:text-sm
                            border-gray-300
                          "
                          placeholder="50"
                          v-bind:class="[
                            !userData.Radius
                              ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                              : '',
                          ]"
                        />
                        <span
                          class="
                            inline-flex
                            items-center
                            px-3
                            rounded-r-md
                            border border-l-0 border-gray-300
                            bg-gray-50
                            text-gray-500
                            sm:text-sm
                          "
                        >
                          km
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Cat section -->
              <div class="pt-6 divide-y divide-gray-200 pb-10">
                <div class="px-4 sm:px-6">
                  <div>
                    <h2 class="text-lg leading-6 font-medium text-gray-900">
                      Categories 
                    </h2>
                    <p class="-mt-4 text-sm text-gray-500">
                      Please select a category.
                    </p>
                  </div>
                  <div class="mt-6 mb-8 md:w-1/3">
                    <label
                      for="location"
                      class="block text-sm font-medium text-gray-700"
                      >Primary Category  <span class="text-red-500">*</span></label
                    >
                    <select
                      @change="changeSubcategories($event.target.value)"
                      id="location"
                      name="location"
                      class="
                        mt-1
                        block
                        w-full
                        pl-3
                        pr-10
                        py-2
                        text-base
                        border-gray-300
                        focus:outline-none
                        focus:ring-indigo-500
                        focus:border-indigo-500
                        sm:text-sm
                        rounded-md
                      "
                      v-bind:class="[
                        !userData.PrimaryCategory
                          ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
                          : '',
                      ]"
                    >
                      <option
                        v-for="category in categories"
                        :key="category.contentItemId"
                        :value="category.category"
                        :selected="category.isSelected"
                      >
                        {{ category.category }}
                      </option>
                    </select>
                  </div>
                  <label
                    for="location"
                    class="block text-sm font-medium text-gray-700 mb-5"
                    >Sub-Category  <span class="text-red-500">*</span></label
                  >

                  <ul
                    role="list"
                    class="
                      mt-3
                      grid grid-cols-4
                      gap-5
                      sm:gap-6 sm:grid-cols-2
                      lg:grid-cols-4
                      ml-0
                      pl-0
                    "
                  >
                    <li
                      class="col-span-1 flex rounded-md"
                      v-for="subcat in modifiedSubcategories"
                      :key="subcat.contentItemId"
                      :value="subcat"
                    >
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input
                            v-model="subcat.isSelected"
                            id="subcat.contentItemId"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            class="
                              focus:ring-indigo-500
                              h-4
                              w-4
                              text-indigo-600
                              border-gray-300
                              rounded
                            "
                            @change="checkForEssentialFieldsCheckbox()"
                          />
                        </div>
                        <div class="ml-3 text-sm">
                          <label
                            for="comments"
                            class="font-medium text-gray-700"
                            >{{ subcat.category }}</label
                          >
                        </div>
                      </div>
                    </li>
                    <li
                      data-parent="comedian"
                      class="hidden col-span-6 flex rounded-md"
                    >
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">test</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="pt-6 divide-y divide-gray-200 pb-10">
                <div class="px-4 sm:px-6">
                  <div class="col-span-12">
                    <h2 class="text-lg leading-6 font-medium text-gray-900">
                      Rate
                    </h2>
                    <!-- <div class="hidden">
                      <Slider
                        v-model="slider.value"
                        v-bind="slider"
                        @update="updateHourlyRate"
                      ></Slider>
                    </div> -->
                    <RadioGroup v-model="selectedrateOptions">
                      <div
                        class="
                          mt-4
                          grid grid-cols-1
                          gap-y-6
                          sm:grid-cols-2 sm:gap-x-4
                          mb-4
                        "
                      >
                        <RadioGroupOption
                          as="template"
                          v-for="rateOption in rateOptions"
                          :key="rateOption.id"
                          :value="rateOption"
                          v-slot="{ checked, active }"
                          v-on:click="
                            updateField('IsFlatRate', rateOption.id == 2)
                          "
                        >
                          <div
                            :class="[
                              checked
                                ? 'border-transparent'
                                : 'border-gray-300',
                              active ? 'ring-2 ring-indigo-500' : '',
                              'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none',
                            ]"
                          >
                            <div class="flex-1 flex">
                              <div class="flex flex-col">
                                <RadioGroupLabel
                                  as="span"
                                  class="
                                    block
                                    text-sm
                                    font-medium
                                    text-gray-900
                                  "
                                >
                                  {{ rateOption.title }}
                                </RadioGroupLabel>
                                <RadioGroupDescription
                                  as="span"
                                  class="
                                    mt-1
                                    flex
                                    items-center
                                    text-sm text-gray-500
                                  "
                                >
                                  {{ rateOption.description }}
                                </RadioGroupDescription>
                              </div>
                            </div>
                            <CheckCircleIcon
                              :class="[
                                !checked ? 'invisible' : '',
                                'h-5 w-5 text-indigo-600',
                              ]"
                              aria-hidden="true"
                            />
                            <div
                              :class="[
                                active ? 'border' : 'border-2',
                                checked
                                  ? 'border-indigo-500'
                                  : 'border-transparent',
                                'absolute -inset-px rounded-lg pointer-events-none',
                              ]"
                              aria-hidden="true"
                            />
                          </div>
                        </RadioGroupOption>
                      </div>
                    </RadioGroup>
                    <div class="grid grid-cols-2 gap-6">
                      <div>
                        <label
                          for="price"
                          class="block text-sm font-medium text-gray-700"
                          >From</label
                        >
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <div
                            class="
                              absolute
                              inset-y-0
                              left-0
                              pl-3
                              flex
                              items-center
                              pointer-events-none
                            "
                          >
                            <span class="text-gray-500 sm:text-sm"> $ </span>
                          </div>
                          <input
                            @input="
                              updateField(
                                'LowerHourlyRate',
                                $event.target.value
                              )
                            "
                            type="text"
                            name="price"
                            id="price"
                            class="
                              focus:ring-indigo-500 focus:border-indigo-500
                              block
                              w-full
                              pl-7
                              py-2
                              pr-12
                              sm:text-sm
                              border-gray-300
                              rounded-md
                            "
                            placeholder="0"
                            aria-describedby="price-currency"
                            :value="userData.LowerHourlyRate"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="price"
                          class="block text-sm font-medium text-gray-700"
                          >To</label
                        >
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <div
                            class="
                              absolute
                              inset-y-0
                              left-0
                              pl-3
                              flex
                              items-center
                              pointer-events-none
                            "
                          >
                            <span class="text-gray-500 sm:text-sm"> $ </span>
                          </div>
                          <input
                            @input="
                              updateField(
                                'UpperHourlyRate',
                                $event.target.value
                              )
                            "
                            type="text"
                            name="price"
                            id="price"
                            class="
                              focus:ring-indigo-500 focus:border-indigo-500
                              block
                              w-full
                              pl-7
                              py-2
                              pr-12
                              sm:text-sm
                              border-gray-300
                              rounded-md
                            "
                            placeholder="0"
                            :value="userData.UpperHourlyRate"
                            aria-describedby="price-currency"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Privacy section -->
              <div class="pt-6 divide-y divide-gray-200">
                <div class="px-4 sm:px-6">
                  <div>
                    <h2 class="text-lg leading-6 font-medium text-gray-900">
                      Privacy
                      <span
                        v-if="privateAccount"
                        class="h-3 w-3 relative inline-flex ml-2"
                      >
                        <span
                          class="
                            animate-ping
                            absolute
                            inline-flex
                            h-full
                            w-full
                            rounded-full
                            bg-purple-400
                            opacity-75
                          "
                        ></span>
                        <span
                          class="
                            relative
                            inline-flex
                            rounded-full
                            h-3
                            w-3
                            bg-purple-500
                          "
                        ></span>
                      </span>
                    </h2>
                    <p class="-mt-4 text-sm text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-3 w-3 inline-block opacity-60"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      Hide your profile from the public listing.
                    </p>
                  </div>
                  <ul
                    role="list"
                    class="mt-2 divide-y divide-gray-200 ml-0 relative"
                    id="privacy"
                  >
                    <SwitchGroup
                      as="li"
                      class="py-4 flex items-center justify-between"
                    >
                      <div class="flex flex-col">
                        <SwitchLabel
                          as="p"
                          class="text-sm font-medium text-gray-900"
                          passive
                        >
                          Set account to "Private"
                        </SwitchLabel>
                        <SwitchDescription class="text-sm text-gray-500">
                          Private accounts are not visible to the public.
                        </SwitchDescription>
                      </div>
                      <Switch
                        v-model="privateAccount"
                        :class="[
                          privateAccount ? 'bg-indigo-600' : 'bg-gray-200',
                          'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500',
                        ]"
                        v-on:click="
                          updateField('IsAccountPrivate', privateAccount)
                        "
                      >
                        <span
                          aria-hidden="true"
                          :class="[
                            privateAccount ? 'translate-x-5' : 'translate-x-0',
                            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                          ]"
                        />
                      </Switch>
                    </SwitchGroup>
                  </ul>
                </div>
                <div class="mt-4 py-4 px-4 flex justify-end sm:px-6">
                  <!-- <button
                    type="button"
                    class="
                      bg-white
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-4
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-sky-500
                    "
                  >
                    Cancel
                  </button> -->
                  <button
                    type="button"
                    class="
                      ml-5
                      border border-transparent
                      rounded-md
                      shadow-sm
                      py-2
                      px-4
                      inline-flex
                      justify-center
                      text-sm
                      font-medium
                      text-white
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-400
                    "
                    v-on:click="saveChanges"
                    :disabled="!hasEssentialFields"
                    v-bind:class="[
                      hasEssentialFields
                        ? 'bg-indigo-600 hover:bg-blue-700'
                        : 'bg-indigo-400 cursor-not-allowed',
                    ]"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>

  <div class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 grid gap-y-2">
    <div
      v-if="privateAccount"
      class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 w-full"
    >
      <div class="p-2 rounded-lg bg-blue-300 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-blue-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  vector-effect="non-scaling-stroke"
                  fill-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden text-black">
                Your profile is private. Your profile is currently not visible
                to the public.
              </span>
              <span class="hidden md:inline text-black">
                Your profile is currently not visible to the public.
              </span>
            </p>
          </div>
          <div
            class="
              order-3
              mt-2
              flex-shrink-0
              w-full
              sm:order-2 sm:mt-0 sm:w-auto
            "
          >
            <a
              href="/Dashboard#privacy"
              class="
                flex
                items-center
                justify-center
                px-4
                py-2
                border border-transparent
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-black-600
                bg-white
                hover:bg-blue-50
              "
            >
              See Privacy Settings
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isPremium"
      class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 w-full hidden"
    >
      <div class="p-2 rounded-lg bg-yellow-400 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-yellow-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  vector-effect="non-scaling-stroke"
                  fill-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden text-black">
                Unlock additional features with a paid membership!
              </span>
              <span class="hidden md:inline text-black">
                Unlock additional features with a paid membership!
              </span>
            </p>
          </div>
          <div
            class="
              order-3
              mt-2
              flex-shrink-0
              w-full
              sm:order-2 sm:mt-0 sm:w-auto
            "
          >
            <a
              href="/Billing"
              class="
                flex
                items-center
                justify-center
                px-4
                py-2
                border border-transparent
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-black-600
                bg-white
                hover:bg-yellow-50
              "
            >
              See Pricing Options
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

    <script>
import { ref } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import { SearchIcon } from "@heroicons/vue/solid";
import {
  BellIcon,
  CreditCardIcon,
  KeyIcon,
  MenuIcon,
  UserCircleIcon,
  // eslint-disable-next-line no-unused-vars
  ViewGridAddIcon,
  PhotographIcon,
  VideoCameraIcon,
  MailIcon,
  XIcon,
} from "@heroicons/vue/outline";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import Slider from "@vueform/slider";
import HeroUploader from "../components/HeroUploader.vue";
import ProfileUploader from "../components/ProfileUploader.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import { OcContentService } from "../services/ocContentService";
import { NopService } from "../services/nopService";
import { UploadcareService } from "../services/uploadcareService";
import { CookieService } from "../services/cookieService";
import { mask } from "vue-the-mask";

const user = {
  name: "John Cooper",
  handle: "johncooper",
  email: "johncooper@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
};
const navigation = [
  { name: "Dashboard", href: "#", current: true },
  { name: "Jobs", href: "#", current: false },
  { name: "Applicants", href: "#", current: false },
  { name: "Company", href: "#", current: false },
];
const subNavigation = [
  {
    name: "Profile",
    href: "/Dashboard",
    icon: UserCircleIcon,
    current: true,
    privacySetting: true,
  },
  { name: "Photos", href: "/Media", icon: PhotographIcon, current: false },
  { name: "Videos", href: "/Videos", icon: VideoCameraIcon, current: false },
  { name: "Password", href: "/Password", icon: KeyIcon, current: false },
  // { name: "Membership", href: "/Billing", icon: CreditCardIcon, current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
const rateOptions = [
  {
    id: 1,
    title: "Hourly",
    description: "I charge by the hour.",
    users: "621 users",
  },
  {
    id: 2,
    title: "Flat Rate",
    description: "I charge a flat rate.",
    users: "1200 users",
  },
];
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    BellIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
    Slider,
    HeroUploader,
    ProfileUploader,
    LoadingSpinner,
    MailIcon,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    CheckCircleIcon,
  },
  directives: { mask },
  setup() {
    const availableToHire = ref(true);
    const allowCommenting = ref(true);
    const allowEmail = ref(true);
    const allowMentions = ref(true);
    const selectedrateOptions = ref(rateOptions[0]);

    return {
      rateOptions,
      selectedrateOptions,
      user,
      navigation,
      subNavigation,
      userNavigation,
      availableToHire,
      allowCommenting,
      allowEmail,
      allowMentions,
    };
  },
  data() {
    return {
      isLoading: true,
      userData: null,
      sectionData: null,
      categories: null,
      subcategories: null,
      modifiedSubcategories: null,
      selectedCategory: null,
      slider: null,
      profileImage: null,
      heroImage: null,
      profileCdnUrl: null,
      heroImageCdnUrl: null,
      privateAccount: false,
      displayPhone: false,
      displayEmail: false,
      displayNameToggle: false,
      isFlatRate: false,
      isPremium: null,
      showSuccess: false,
      showFailure: false,
      hasEssentialFields: false,
      oldImageToRemove: null,
      oldProfileToRemove: null,
    };
  },
  created() {
    this.isLoading = true;
    this.fetchUserData();
    this.fetchOcData();
    this.isLoading = false;
  },
  methods: {
    async fetchUserData() {
      let id = this.getLoginCookie();
      if (id) {
        this.userData = await NopService.GetCustomerDetailsById(id);
        this.privateAccount = this.userData.IsAccountPrivate;
        this.isPremium = this.userData.IsPremium;
        this.displayName = this.userData.DisplayName;
        this.displayEmail = this.userData.DisplayEmail;
        this.displayPhone = this.userData.DisplayPhone;
        this.displayNameToggle = this.userData.ShowDisplayName;
        this.selectedrateOptions = this.userData.IsFlatRate
          ? ref(rateOptions[1])
          : ref(rateOptions[0]);
        this.emitter.emit("user-changed", this.userData);
        await this.fetchCategories();
        await this.fetchSubcategories();
        this.checkForEssentialFields();
      } else {
        this.emitter.emit("logged-in", false);
      }

      var section = this.$router.currentRoute.value.hash.replace("#", "");
      if (section)
        this.$nextTick(() =>
          window.document.getElementById(section).scrollIntoView()
        );
    },
    async fetchOcData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Settings - Profile",
        "KrickersSectionContent"
      );
    },
    async fetchCategories() {
      let categories = await OcContentService.fetchMainCategories();
      let modifiedCategories = [];
      categories.forEach((cat) => {
        if (cat.category == process.env.VUE_APP_DEFAULT_SELECTION) {
          if (
            this.userData.PrimaryCategory == "" ||
            this.userData.PrimaryCategory == null
          ) {
            modifiedCategories.push({ ...cat, isSelected: true });
          }
        } else {
          modifiedCategories.push({
            ...cat,
            isSelected: this.userData.PrimaryCategory == cat.category,
          });
        }
      });

      modifiedCategories.forEach((mc) => {
        if (mc.isSelected) {
          this.selectedCategory = mc.category;
        }
      });
      this.categories = modifiedCategories.sort((a, b) =>
        a.category < b.category ? -1 : a.category > b.category ? 1 : 0
      );
    },
    async fetchSubcategories() {
      let subcategories = await OcContentService.fetchSubCategories();
      this.subcategories = [];
      let modifiedSubcategories = [];
      subcategories.forEach((subcat) => {
        if (subcat.category !== process.env.VUE_APP_DEFAULT_SELECTION) {
          let modifiedSubcategoryItem = {
            ...subcat,
            isSelected: this.userData.Categories.includes(subcat.category),
          };
          this.subcategories.push(modifiedSubcategoryItem);
          if (subcat.parentCategory.contentItems[0]) {
            if (
              this.selectedCategory ==
              subcat.parentCategory.contentItems[0].category
            ) {
              modifiedSubcategories.push(modifiedSubcategoryItem);
            }
          }
        }
      });
      this.modifiedSubcategories = modifiedSubcategories;
    },
    changeSubcategories(parent) {
      this.selectedCategory = parent;
      this.modifiedSubcategories = this.subcategories.filter(
        (subcat) =>
          subcat.parentCategory.contentItems[0]
            ? subcat.parentCategory.contentItems[0].category == parent
            : true // captures the default empty category
      );
      //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa")
      //console.log(this.selectedCategory)
      this.checkForEssentialFields();
    },
    getSelectedCategory() {
      let selected = "";
      this.categories.forEach((cat) => {
        if (cat.isSelected) {
          selected = cat.category;
        }
      });
      return selected;
    },
    getSelectedSubcategories(parent) {
      let selected = "";
      this.modifiedSubcategories.forEach((sub) => {
        if (
          sub.isSelected &&
          sub.parentCategory.contentItems[0].category == parent
        ) {
          selected += sub.category + ",";
        }
      });
      return selected;
    },
    onHeroUpload(value) {
      this.heroImage = value;
    },
    onOldImageToRemove(value) {
      this.oldImageToRemove = value;
    },
    onOldProfileToRemove(value) {
      this.oldProfileToRemove = value;
    },
    onProfileUpload(value) {
      this.profileImage = value;
    },
    async saveChanges() {
      this.isLoading = true;
      this.showSuccess = false;
      this.showFailure = false;
      this.userData["IsActive"] = 1;
      //update categories
      this.userData.PrimaryCategory = this.selectedCategory;
      this.userData.Categories = this.getSelectedSubcategories(
        this.selectedCategory
      );
      //upload images and then save the uuid
      if (this.heroImage) {
        // let heroImageResponse = await UploadcareService.uploadHeroImage(
        //   this.heroImage.name,
        //   this.heroImage
        // );
        this.userData.HeroImageUUID = this.heroImage.uuid; //heroImageResponse.data[this.heroImage.name];
      }
      if (this.profileImage) {
        // let profileImageResponse = await UploadcareService.uploadProfileImage(
        //   this.profileImage.name,
        //   this.profileImage
        // );
        this.userData.ProfileImageUUID = this.profileImage.uuid; //profileImageResponse.data[this.profileImage.name];
      }
      let resp = await NopService.UpdateCustomer(this.userData);
      if (resp.Status === 1) {
        if (this.oldImageToRemove) {
          await UploadcareService.deleteImage(this.oldImageToRemove);
        }
        if (this.oldProfileToRemove) {
          await UploadcareService.deleteImage(this.oldProfileToRemove);
        }
        this.emitter.emit("user-changed", resp.Data);
        this.showSuccess = true;
      } else {
        this.showFailure = true;
      }
      this.isLoading = false;
      this.scrollToTop();
    },
    updateField(field, value) {
      this.userData[field] = value;
      this.checkForEssentialFields();
    },
    getLoginCookie() {
      return CookieService.getLoginCookie();
    },
    checkForEssentialFieldsCheckbox() {
      this.$nextTick(() => {
        this.checkForEssentialFields();
      })
    },
    checkForEssentialFields() {
      let primaryCategory = this.selectedCategory;
      let subcategories = this.getSelectedSubcategories(primaryCategory);
      //console.log(primaryCategory)
      //console.log(subcategories)
      //console.log(this.userData.City)
      //console.log(this.userData.FirstName)
      //console.log(this.userData.LastName)
      //console.log(this.userData.ZipPostalCode)
      if (
        primaryCategory && subcategories &&
        this.userData.FirstName &&
        this.userData.LastName &&
        // this.userData.Email &&
        this.userData.City &&
        // this.userData.Province &&
        //this.userData.Radius &&
        this.userData.ZipPostalCode
      ) {
        this.hasEssentialFields = true;
        if (this.displayNameToggle) {
          if (!this.userData.DisplayName) {
            this.hasEssentialFields = false;
          }
        }
      } else {
        this.hasEssentialFields = false;
      }
    },
    generateProfileUrl() {
      if (!this.userData.Username) {
        var randomChars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var result = "";
        for (var i = 0; i < 20; i++) {
          result += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
          );
        }
        this.userData.Username = result;
      }
    },
    scrollToTop() {
      //topofpage
      let element = this.$refs["topofpage"];
      let top = element.offsetTop;
      window.scrollTo(0, top)
    },
  },
};
</script>
 <style src="@vueform/slider/themes/default.css">
 </style>